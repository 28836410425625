import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './counterSlice';
import userSlice from './userSlice';
import collectionSlice from './collectionSlice';

export const store = configureStore({
	reducer: {
		counter: counterReducer,
		user: userSlice,
		collections: collectionSlice
	},
});
