import { useEffect } from 'react';
import { useNetworkMismatch,useSwitchChain, useSigner} from '@thirdweb-dev/react';
import Footer from "./footer";
import Wallet_modal from "./modal/wallet_modal";
import BidsModal from "./modal/bidsModal";
import { useRouter } from "next/router";
import Header01 from "./header/Header01";
import Header02 from "./header/Header02";
import Header03 from "./header/Header03";
import Header04 from "./header/Header04";
import { getProperChain } from '../utils/helpers';
import { Config } from '../utils/config';
import { SdkService } from '../utils/sdk_service';

export default function Layout({ children }) {
  const route = useRouter();
  const signer = useSigner();
  const isNetworkMismatch = useNetworkMismatch();
  const switchChain = useSwitchChain();
  const activeChain = getProperChain();

  const reInstantiateSdk = async () => {
    await SdkService.updateInstance({signer});
  }

  useEffect(() => {
    if(!signer) return;

    reInstantiateSdk()
  }, [signer]);

  useEffect(() => {
    if (isNetworkMismatch) {
      switchChain(activeChain?.chainId);
      swal(Config.SWAL_MESSAGES.WRONG_NETWORK.TITLE, Config.SWAL_MESSAGES.WRONG_NETWORK.DESCRIPTION, 'warning');
    }
  }, [isNetworkMismatch]);
  // header start
  let header;
  if (
    route.asPath === "/home/home_3" ||
    route.asPath === "/home/home_9" ||
    route.asPath === "/maintenance" ||
    route.asPath === "/home/home_12"
  ) {
    header = <Header02 />;
  } else if (route.asPath === "/platform_status") {
    header = <Header03 />;
  } else if (route.asPath === "/home/home_8") {
    header = <Header04 />;
  } else {
    header = <Header01 />;
  }
  // header end

  return (
    <>
      {header}
      <Wallet_modal />
      <BidsModal />
      <main>{children}</main>
      <Footer />
    </>
  );
}
