import { ThirdwebSDK } from '@thirdweb-dev/sdk';
import { Config } from './config';
import {
  approvedContracts,
  nftContractAddresses,
  marketplaceContractAddresses,
  cardsNftContractAddresses,
  networkToUse
} from '../components/contractAddress';
import { getProperChain } from './helpers';
import { ethers } from "ethers"
import swal from 'sweetalert';

class SdkService {
  shoppingCart = [];
  sdkInstance;

  constructor(wallet = process.env.NEXT_PUBLIC_PRIVATE_KEY){
    const signer = new ethers.Wallet(wallet);
    this.shoppingCart = [];
    this.sdkInstance = ThirdwebSDK.fromSigner(signer, getProperChain(), {
      clientId: process.env.NEXT_PUBLIC_NEW_THIRD_WEB_API_KEY
    });
  }

  async updateInstance(props) {
    const {signer} = props;

    this.sdkInstance = ThirdwebSDK.fromSigner(signer, getProperChain(), {
      clientId: process.env.NEXT_PUBLIC_NEW_THIRD_WEB_API_KEY
    });
  }

  async getContract(contractAddress) {
    let contract;

    try {
      contract = await this.sdkInstance.getContract(contractAddress).catch((error) => {
        console.log('Fetching Contract Error: ', error);
      });
    } catch (error) {
      console.log('Caught: Fetching Contract Error: ', error);
    }

    return contract;
  }

  // @return All Valid Listings
  async getAllValidListings(contractAddress) {
    const contract = await this.getContract(contractAddress);

    return await contract.directListings.getAllValid();
  }

  // @return All Nfts
  async getAllNfts(contractAddress, type) {
    const contract = await this.getContract(contractAddress);

    return await contract?.[type].getAll();
  }

  // @return All Owned NFfts
  async getOwnedNfts(contractAddress, type, walletAddress) {
    const contract = await this.getContract(contractAddress);

    return await contract?.[type].getOwned(walletAddress);
  }

  // @return metadata
  async getMetadata(contractAddress) {
    const contract = await this.getContract(contractAddress);

    return await contract.metadata.get();
  }

  // @return Total Count
  async getTotalCount(contractAddress, type) {
    const contract = await this.getContract(contractAddress);

    if (contractAddress === nftContractAddresses[networkToUse]){
      let totalCount = await contract?.[type].totalCirculatingSupply().then(v => { return v.toNumber() });
      return totalCount;
    }
    else if(contractAddress === cardsNftContractAddresses[networkToUse]) {
      const totalCount1 = await contract?.[type].totalCirculatingSupply('0').then(v => { return v.toNumber() });
      const totalCount2 = await contract?.[type].totalCirculatingSupply('1').then(v => { return v.toNumber() });
      const totalCount3 = await contract?.[type].totalCirculatingSupply('2').then(v => { return v.toNumber() });
      const totalCount4 = await contract?.[type].totalCirculatingSupply('3').then(v => { return v.toNumber() });
      const totalCount5 = await contract?.[type].totalCirculatingSupply('4').then(v => { return v.toNumber() });
      let totalCount = totalCount1 + totalCount2 + totalCount3 + totalCount4 + totalCount5;
      return totalCount;
    }
    else {
      let totalCount = await contract?.[type].totalCirculatingSupply('0').then(v => { return v.toNumber() });
      return totalCount;
    }
  }

  // @return owners count
  async getOwnersCount(contractAddress, type) {
    const contract = await this.getContract(contractAddress);

    return await contract?.[type]?.getAllOwners().then((res) => new Set(res.map(el => el.owner)).size);
  }

  async getOwners(contractAddress, type) {
    if (type === 'erc1155') {
      let resp = await this.getTotalCount(contractAddress, type);
      return resp;
    }
    else if(type === 'erc721') {
      let resp = await this.getOwnersCount(contractAddress, type);
      return resp;
    }

    return 0;
  }

  // list Nft implement and replace on all places that we have list now
  // async listNow(itemData) => {TBD}

  // @calls buy now
  async buyNow(item_id) {
    // use the collection contract
    const contract = await this.getContract(marketplaceContractAddresses[networkToUse]);

    try {
      const res = await contract.directListings.buyFromListing(item_id, 1)
        .then((response) => response);

        if (res) return res;
    } catch (e) {
      swal(Config.SWAL_MESSAGES.PURCHASE_FAILED, Config.SWAL_MESSAGES.TRY_AGAIN, 'error');
      return;
    }

    swal(Config.SWAL_MESSAGES.CONGRATS, Config.SWAL_MESSAGES.PURCHASE_SUCCESS, 'success');
  }

  async prepareTransfer(token_id, wallet, address) {
    const contractAddress = approvedContracts.find((el) => el.address.toLowerCase() === address.toLowerCase());

    if(!contractAddress) return;

    const contract = await this.getContract(contractAddress.address);
    let tx;

    try {
      tx = await contract?.[contractAddress?.type].transfer.prepare(wallet, token_id, 1);
    } catch (e) {
      swal('Incorrect Address', 'The provided address is incorrect', 'error')
      return;
    }

    return {key: token_id, tx}
  }

  // @calls add new transaction to the call
  async addListingToSweepMode(item_id) {
    const contract = await this.getContract(marketplaceContractAddresses[networkToUse]);
    // Does not find this functionality
    const tx = await contract.directListings.buyFromListing.prepare(item_id, 1);

    tx.setOverrides({
      value: ethers.utils.parseEther(ethers.utils.formatEther(tx.overrides?.value?._hex))
    })
    // optional add view on block explorer
    // const sentTx = await tx.send();
    // const txHash = sentTx.hash;
    this.shoppingCart.push({key: item_id, transaction: tx});
  }

  // @calls remove transaction from list
  async removeListingFromSweepMode(item_id) {
    this.shoppingCart = this.shoppingCart.filter(({key, _transaction}) => key !== item_id);
  }

  // @calls execute multiclal transaction
  async executeBatchBuy() {
    if (this.shoppingCart.length === 0) return;

    const contract = await this.getContract(marketplaceContractAddresses[networkToUse]);
    try {
      const values = this.shoppingCart.map((objectTx) => objectTx.transaction.encode());
      const overrideValue = this.shoppingCart.reduce((acc, {transaction}) => acc + Number(ethers.utils.formatEther(transaction.overrides?.value?._hex)), 0).toString();
      const parsedValue = ethers.utils.parseEther(overrideValue);

      await contract.call("multicall", [values], {value: parsedValue});

      return true;
    } catch (e) {
      console.log('Batch Buy failed with: ', e?.message);
      return false;
    }
  }

  async executeBatchTransfer(transactions) {
    if (transactions.length === 0) return;

    const contract = await this.getContract(transactions[0].contract.address);

    try {
      const values = transactions.map((tx) => tx.encode());

      await contract.call("multicall", [values]);

      return true;
    } catch (e) {
      console.log('Batch Transfer failed with: ', e?.message);
      throw new Error('Batch Transfer Failer')
    }

  }

  async cancelListing (id) {
    const contract = await this.getContract(marketplaceContractAddresses[networkToUse]);

    try {
      const response = await contract.directListings.cancelListing(id)

      if (response) {
        swal(Config.SWAL_MESSAGES.CANCEL_LISTING_SUCCESS.TITLE, Config.SWAL_MESSAGES.CANCEL_LISTING_SUCCESS.DESCRIPTION, 'success');
      }
    } catch (error) {
      swal(Config.SWAL_MESSAGES.CANCEL_LISTING_FAILED, Config.SWAL_MESSAGES.TRY_AGAIN, 'error');
      return;
    };
  }
}

const SdkServiceInstance = new SdkService();

export {SdkServiceInstance as SdkService}