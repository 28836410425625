const TEST_ENV = 'test';
const PROD_ENV = 'production';

const MESSAGES = {
  NO_WALLET: {
    TITLE: 'No Wallet Connected',
    DESCRIPTION: 'Please connect a wallet',
  },
  NO_TRANSFER_WALLET: {
    TITLE: 'Transfer Wallet Incorrect',
    DESCRIPTION: 'Please provide a correct wallet.'
  },
  TRANSFER_FAILED: {
    TITLE: 'Batch Transfer Failed',
    DESCRIPTION: 'Please try again later or contact us.'
  },
  WRONG_NETWORK: {
    TITLE: 'Wrong Network',
    DESCRIPTION: 'Please switch to zkSync'
  },
  LISTING_SUCCESS: {
    TITLE: 'Successful Listing!',
    DESCRIPTION: 'Listing created successfully.'
  },
  FETCH_DATA_FAILED: {
    TITLE: 'Failed fetching data',
    DESCRIPTION: 'Something happened while fetching data'
  },
  GENERAL_SUCCESS: {
    TITLE: 'Successful Transaction',
    DESCRITION: 'Successfully executed transactions'
  },
  MINTING_FAILED: 'Minting failed.',
  MINTING_SUCCESS: 'Minting was successful.',
  PURCHASE_FAILED: 'NFT purchase failed.',
  PURCHASE_SUCCESS: 'NFT successfully purchased',
  CANCEL_LISTING_FAILED: 'Failed to cancel.',
  CANCEL_LISTING_SUCCESS: {
    TITLE: 'Confirmed.',
    DESCRIPTION: 'You successfully canceled your listing.'
  },
  TRY_AGAIN: 'Please try again later.',
  PRICE_MISSING: 'Price must be provided when creating a listing',
  SOMETHING_WENT_WRONG: 'Something went wrong!',
  CONGRATS: 'Congrats!',
  LISTING_FAILED: 'Listing Failed!',
  OWNERS_FAILED: 'Failed fetching owners'
};

const ACTIVE_CHAIN = {
  // === Required information for connecting to the network === \\
  chainId: 324, // Chain ID of the network
  // Array of RPC URLs to use
  rpc: ["https://mainnet.era.zksync.io"],

  // === Information for adding the network to your wallet (how it will appear for first time users) === \\
  // Information about the chain's native currency (i.e. the currency that is used to pay for gas)
  nativeCurrency: {
      decimals: 18,
      name: "ZksyncEra",
      symbol: "ETH",
  },
  shortName: "zksync", // Display value shown in the wallet UI
  slug: "consensys", // Display value shown in the wallet UI
  testnet: false, // Boolean indicating whether the chain is a testnet or mainnet
  chain: "ZksyncEra", // Name of the network
  name: "ZksyncEra", // Name of the network
};

const TEST_CHAIN = {
  // === Required information for connecting to the network === \\
  chainId: 280, // Chain ID of the network
  // Array of RPC URLs to use
  rpc: ["https://testnet.era.zksync.dev"],

  // === Information for adding the network to your wallet (how it will appear for first time users) === \\
  // Information about the chain's native currency (i.e. the currency that is used to pay for gas)
  nativeCurrency: {
      decimals: 18,
      name: "ZksyncEraTestnet",
      symbol: "ETH",
  },
  shortName: "zksync", // Display value shown in the wallet UI
  slug: "consensys", // Display value shown in the wallet UI
  testnet: true, // Boolean indicating whether the chain is a testnet or mainnet
  chain: "ZksyncEraTestnet", // Name of the network
  name: "ZksyncEraTestnet", // Name of the network
};

export const Config = {
  SWAL_MESSAGES: MESSAGES,
  CHAINS: {
    MAINNET: ACTIVE_CHAIN,
    TESTNET: TEST_CHAIN
  },
  ENVIRONMENTS: {
    TEST: TEST_ENV,
    PROD: PROD_ENV
  }
}