import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  collections: [],
  nfts: []
};

export const collectionSlice = createSlice({
  name: "collection",
  initialState,
  reducers: {
    addCollections: (state, action) => {
      state.collections = action.payload
    },
    addNfts: (state, action) => {
      state.nfts = action.payload;
    }
  },
});

export const {addCollections, addNfts} = collectionSlice.actions;

export default collectionSlice.reducer;
