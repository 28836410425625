import Head from 'next/head';

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>{title} || Traki NFT Marketplace</title>
        <link rel='icon' href='/favicon.png' />
        <meta name='description' content={desc} />
        <meta name='keyword' content={keyword} />
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: 'Traki | NFT Marketplace',
  keyword:
    'bitcoin, blockchain, ethereum, zkSync, crypto, crypto collectibles, crypto marketplace, cryptocurrency, digital items, market, nft, nft marketplace, nft next js, NFT react, non-fungible tokens, virtual asset, wallet',
  desc: "The World's Largest Hub For FUN Interactive Digital Experiences - Connecting Gamers, Brands, Creators & Communities!"
};

export default Meta;
