export const properties = [
  "Background",
  "Fur",
  "Clothes",
  "Mood",
  "Eyewear",
  "Head",
  "Honorary",
  "Unique",
  "name"
]

export const DEFAULT_FILTERS = {
  Background: [],
  Fur: [],
  Clothes: [],
  Mood: [],
  Eyewear: [],
  Head: [],
  Honorary: [],
  Unique: [],
  name: []
}