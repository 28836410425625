import { properties } from "../data/properties";

const exportPropertiesValues = (data) => {
  const storage = {};
  properties.forEach((property) => storage[property] = []);

  data.forEach(element => {
    if(isActiveListing(element)){
      const {asset: { attributes = [] }} = element;
      attributes.forEach((trait) => storage[trait.trait_type] = [...storage[trait.trait_type], trait.value])
    } else {
      const {metadata: { attributes = [] }} = element;
      attributes.forEach((trait) => storage[trait.trait_type] = [...storage[trait.trait_type], trait.value])
    }
  });

  // return unique values
  return properties.map((trait) => ({[trait]: Array.from(new Set(storage[trait]))}));
}

const isActiveListing = (nft) => {
  return nft.hasOwnProperty("asset") && nft.hasOwnProperty("creatorAddress");
};

const stripStr = (str) => {
  return str.replace(/\s/g, "").toLowerCase();
};

export {isActiveListing, stripStr, exportPropertiesValues}