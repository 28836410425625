import { isProduction } from "../utils/helpers";

export const marketplaceContractAddresses = {
  mumbai: '0x74995Dea36AA8178daA965d732C6308d2240d256',
  // zkSyncTestnet: '0x73fCBc87E911454cFD189Dd6659c1acD8F7078D5',
  zkSyncTestnet: '0x24220Ef5F88d490Fb69bc766340db470FeA3e601',
  zkSyncMainnet: '0x2b01f073E49F505e1FfF2a91D28060aee34e02c7'
};

export const nftContractAddresses = {
  mumbai: '0xA68ca8b46e1976f4E998BB9f047151D77FD756ED',
  //zkSyncTestnet: '0x9df83C62Cfc24fCF5129740B5c6002268c3b248c'
  zkSyncTestnet: '0x74577E3435F74a956311140F076886e75fe6DFde',
  zkSyncMainnet: '0x46f33E15dA896849CA0BE4450F09e583853d6E8E'
};

export const cardsNftContractAddresses = {
  zkSyncTestnet: '0x7Df8d7577E7f21d11435635C3fFffe76c221af2b',
  zkSyncMainnet: '0x3F9931144300f5Feada137d7cfE74FAaa7eF6497'
};

export const allowPassNftContractAddresses = {
  zkSyncTestnet: '0x2E08EE1F09d07fDD852073668cF886c6f812e22c',
  zkSyncMainnet: '0x54948AF9D4220aCee7aA5340C818865F6B313f96'
};

export const networkToUse = isProduction() ? 'zkSyncMainnet' : 'zkSyncTestnet';

export const approvedContracts = [
  { address: nftContractAddresses[networkToUse], type: 'erc721' },
  { address: cardsNftContractAddresses[networkToUse], type: 'erc1155' },
  { address: allowPassNftContractAddresses[networkToUse], type: 'erc1155' },
];
