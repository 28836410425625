import { createSlice } from "@reduxjs/toolkit";
import { addItemToBatch, removeItemFromBatch } from "../utils/sdk_service_helpers";

const initialState = {
  shoppingCart: [],
  showShoppingCart: false,
  showSweepMode: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      // action.payload to be array
      action.payload.forEach((asset) => {
        const objectKey = asset.hasOwnProperty('metadata') ? 'metadata' : 'asset';

        if (state.shoppingCart.findIndex((el) => el?.[objectKey]?.id === asset?.[objectKey]?.id) !== -1) return;
  
        addItemToBatch(asset.id);
        state.shoppingCart.push(asset);
      });
    },
    removeFromCart: (state, action) => {
      action.payload.forEach((asset) => {
        const objectKey = asset.hasOwnProperty('metadata') ? 'metadata' : 'asset';

        state.shoppingCart = state.shoppingCart.filter((el) => el?.[objectKey]?.id !== asset?.[objectKey]?.id);

        removeItemFromBatch(asset?.id);
      })
    },
    toggleShowShoppingCart: (state, action) => {
      state.showShoppingCart = action.payload;
    },
    toggleShowSweepMode: (state, action) => {
      state.showSweepMode = action.payload;
    },
    clearCart: (state, action) => {
      state.shoppingCart = [];
    }
  },
});

export const {addToCart,removeFromCart, toggleShowShoppingCart, toggleShowSweepMode, clearCart} = userSlice.actions;

export default userSlice.reducer;
