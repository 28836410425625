import { SdkService } from "./sdk_service";

const addItemToBatch = async (item_id) => {
  if (!item_id || item_id === "") return;

  try {
    await SdkService.addListingToSweepMode(item_id);
  } catch (e) {
    console.log('Add to batch failed with: ', e?.message);
  }
}

const removeItemFromBatch = async (item_id) => {
  if (!item_id || item_id === "") return;

  try {
    await SdkService.removeListingFromSweepMode(item_id);
  } catch (e) {
    console.log('Remove from batch failed with: ', e?.message);
  }
}

export {addItemToBatch, removeItemFromBatch};