import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {IoMdClose} from 'react-icons/io';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { isActiveListing } from '../../utils/filtersHelpers';
import swal from 'sweetalert';
import {SdkService} from '../../utils/sdk_service';
import { Loader } from "../Loader/Loader";
import { Config } from '../../utils/config';
import { clearCart } from '../../redux/userSlice';


const CheckoutDrawer = () => {
  const dispatch = useDispatch();
  const isDrawerOpen = useSelector((state) => state.user.showShoppingCart);
  const selectedItems = useSelector((state) => state.user.shoppingCart);
  const [isOpen, setIsOpen] = useState(false);
  const [buyingLoading, setBuyingLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    if(!isDrawerOpen) return;

    setIsOpen(isDrawerOpen);
  }, [isDrawerOpen]);

  useEffect(() => {
    if (!selectedItems.length) return;

    const amount = selectedItems.reduce((acc, currItem) => currItem?.currencyValuePerToken?.displayValue ? acc += parseFloat(currItem?.currencyValuePerToken?.displayValue) : acc, 0);
    setTotalAmount(amount);
  }, [selectedItems]);

  const executeBuy = async () => {
    if (buyingLoading) return;

    setBuyingLoading(true);
    let resp;

    try {
      resp = await SdkService.executeBatchBuy();
    } catch (e) {
      console.log('Something Failed with: ', e);
      swal(Config.SWAL_MESSAGES.SOMETHING_WENT_WRONG, Config.SWAL_MESSAGES.TRY_AGAIN, 'warning');
    } finally {
      setBuyingLoading(false);
      resp && swal(Config.SWAL_MESSAGES.GENERAL_SUCCESS.TITLE, Config.SWAL_MESSAGES.GENERAL_SUCCESS.DESCRITION, 'success')
      dispatch(clearCart());
    }
  }

  const toggleDrawer = (open) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;

      setIsOpen(open);
    };

    // fix list item to display Items
  const list = () => (
    <Box
      sx={{ width: 400, heigth: '100%' }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List className='border-b-2 dark:border-jacarta-500 light:border-jacarta-700'>
        {selectedItems.length > 0 ? selectedItems.map((element, idx) => {
          const itemData = isActiveListing(element) ? element.asset : element.metadata;

          return (
            <React.Fragment key={itemData.id + ' ' + idx}>
              <ListItem key={itemData.id + ' ' + idx} alignItems="flex-start" className='flex-col'>
                <div className='flex flex-row'>
                  <ListItemAvatar>
                    <Avatar
                      alt={itemData.name}
                      src={itemData.image}
                      variant="square"
                      sx={{width: '50px', height: '50px', marginRight: '15px', borderRadius: '3px', marginTop: '5px'}}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography
                          variant="h6"
                          className='dark:text-jacarta-100 light:text-jacarta-700'
                        >
                          {itemData.name}
                        </Typography>
                      </React.Fragment>}
                    secondary={
                      <React.Fragment>
                        <Typography
                          variant='span'
                          className='dark:text-jacarta-100 light:text-jacarta-700 text-ellipsis overflow-hidden'
                        >
                          {itemData.description}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </div>
                <div className="dark:border-jacarta-600 border-jacarta-100 mb-2 flex items-center py-2.5 pt-5 text-lg justify-between w-full">
                  <span className="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
                    Price
                  </span>
                  <div className="ml-auto">
                    <span className="flex items-center whitespace-nowrap">
                      <span data-tippy-content="ETH">
                        <svg className="h-4 w-4">
                          <use xlinkHref="/icons.svg#icon-ETH"></use>
                        </svg>
                      </span>
                      <span className="text-green font-medium tracking-tight">
                        {element?.currencyValuePerToken?.displayValue ?? 0} ETH
                      </span>
                    </span>
                  </div>
                </div>
              </ListItem>
              {idx + 1 < selectedItems.length ? <Divider className='dark:bg-jacarta-300 light:bg-jacarta-700' variant="inset" component="li"/> : null}
            </React.Fragment>
          )
        }): null}
      </List>
    </Box>
  );

  return (
    <div>
        <div key="checkout-drawer" className="px-2 py-15">
          <Button onClick={toggleDrawer(true)}>Continue</Button>
          <Drawer
            classes={{
              paperAnchorRight: 'dark:bg-jacarta-700 bg-white'
            }}
            anchor={'right'}
            open={isOpen}
            onClose={toggleDrawer(false)}
          >
            <div className='flex flex-row justify-between items-center px-10 border-b-2 dark:border-jacarta-500 light:border-jacarta-700'>
              <div>
                <Typography
                  className='dark:text-jacarta-100 light:text-jacarta-700'
                  variant="h5"
                  paddingY='20px'
                  paddingx='10px'
                >
                  Shopping Cart
                </Typography>
              </div>
              <div>
                <button
                  onClick={toggleDrawer(false)}
                  className='dark:text-jacarta-100 light:text-jacarta-700'
                >
                  <IoMdClose width={30} height={30}/>
                </button>
              </div>
            </div>
            {list()}
            {selectedItems.length > 0 ?
              <div className="px-5 pt-5 pb-5 border-b-2 dark:border-jacarta-500 light:border-jacarta-700">
                <Typography
                  className='dark:text-jacarta-100 light:text-jacarta-700'
                  variant="h6"
                  paddingX='10'
                  paddingY='10'
                >
                  Total Price: {totalAmount} ETH
                </Typography>
              </div>
              : null}
            <div className='px-5 pt-5'>
              <Button
                variant='outlined'
                onClick={() => executeBuy()}
                disabled={buyingLoading}
              >
                Finish Payment
                {buyingLoading ? <Loader /> : null}
              </Button>
            </div>
          </Drawer>
        </div>
    </div>
  );
}

export default CheckoutDrawer;