import {
  ThirdwebProvider,
  paperWallet,
  metamaskWallet,
  coinbaseWallet,
  walletConnect,
} from '@thirdweb-dev/react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { ThemeProvider } from 'next-themes';
import { useRouter } from 'next/router';
import { useRef } from 'react';
import { Provider } from 'react-redux';
import Layout from '../components/layout';
import Meta from '../components/Meta';
import UserContext from '../components/UserContext';
import { store } from '../redux/store';
import { Config } from '../utils/config';
import '../styles/globals.css';
import { isProduction } from '../utils/helpers';

const theme = createTheme({});

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const pid = router.asPath;
  const scrollRef = useRef({
    scrollPos: 0
  });

  return (
    <>
      <Meta title='Home 1' />

      <ThirdwebProvider
          activeChain={isProduction() ? Config.CHAINS.MAINNET : Config.CHAINS.TESTNET}
          clientId={process.env.NEXT_PUBLIC_THIRD_WEB_API_KEY}
          supportedWallets={[
            metamaskWallet(),
            walletConnect(),
            coinbaseWallet(),
            paperWallet({
              paperClientId: process.env.NEXT_PUBLIC_PAPER_API_KEY,
            }),
          ]}
      >
        <Provider store={store}>
          <MuiThemeProvider theme={theme}>
            <ThemeProvider enableSystem={true} attribute='class'>
              <UserContext.Provider value={{ scrollRef: scrollRef }}>
                {pid === '/login' ? (
                  <Component {...pageProps} />
                ) : (
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                )}
              </UserContext.Provider>
            </ThemeProvider>
          </MuiThemeProvider>
        </Provider>
      </ThirdwebProvider>
    </>
  );
}

export default MyApp;
