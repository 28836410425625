import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from "next-themes";
import Image from 'next/image';
import Link from 'next/link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import DarkMode from '../mode/DarkMode';
import Logo from './../../public/artwork/logo.png';
import WhiteLogo from './../../public/artwork/logo_white.png';
import { v4 as uuidv4 } from 'uuid';
import { useRouter } from 'next/router';
import {
  isChildrenPageActive,
  isParentPageActive
} from '../../utils/daynamicNavigation';
import { ConnectWallet, useAddress, useDisconnect } from '@thirdweb-dev/react';
import { MdShoppingCart } from 'react-icons/md';
import CheckoutDrawer from '../checkout/CheckoutDrawer';
import { toggleShowShoppingCart } from '../../redux/userSlice';
import { isActiveListing } from '../../utils/filtersHelpers';
import { getProperChain } from '../../utils/helpers';

export default function Header01() {
  const { theme } = useTheme();
  const address = useAddress();
  const disconnect = useDisconnect();
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [isCollapse, setCollapse] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const selectedItems = useSelector((state) => state.user.shoppingCart);
  const showShoppingCart = useSelector((state) => state.user.showShoppingCart);
  const showSweepMode = useSelector((state) => state.user.showSweepMode);

  let dropdownClasses = 'dropdown-menu dark:bg-jacarta-800 group-dropdown-hover:opacity-100 group-dropdown-hover:visible !-right-4 !top-[85%] !left-auto z-10 min-w-[14rem] max-h-[20em] whitespace-nowrap overflow-scroll rounded-xl bg-white transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full lg:absolute lg:grid lg:!translate-y-4 lg:py-4 lg:px-2 lg:shadow-2xl hidden lg:invisible lg:opacity-0';

  // Ask Mitko how e prefers this here
  // if (!showSweepMode || selectedItems.length == 0) dropdownClasses = dropdownClasses + ' hidden lg:invisible lg:opacity-0';
  // if (showSweepMode && selectedItems.length > 0) dropdownClasses = dropdownClasses + ' lg:visible lg:opacity-100';

  const toggleShoppingCart = () => {
    dispatch(toggleShowShoppingCart(!showShoppingCart))
  }

  useEffect(() => {
    if (!selectedItems.length) return;

    const amount = selectedItems.reduce((acc, currItem) => currItem?.currencyValuePerToken?.displayValue ? acc += parseFloat(currItem?.currencyValuePerToken?.displayValue) : acc, 0);
    setTotalAmount(amount);
  }, [selectedItems]);

  // window resize
  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 1024) {
        setToggle(false);
      }
    });
  });

  const route = useRouter();
  /* -------------------------------------------------------------------------- */
  /*                            daynamic navigations                            */
  /* -------------------------------------------------------------------------- */
  const home = {
    id: 1,
    name: 'Home'
  };

  const list = {
    id: 2,
    name: 'List'
  };

  const mint = {
    id: 3,
    name: 'Mint'
  };

  const resource = {
    id: 4,
    name: 'Resources',
    pages: [
      {
        id: uuidv4(),
        name: 'Help Center',
        path: '/help_center'
      },
      {
        id: uuidv4(),
        name: 'Platform Status',
        path: '/platform_status'
      },
      {
        id: uuidv4(),
        name: 'Partners',
        path: '/partners'
      },
      {
        id: uuidv4(),
        name: 'Blog',
        path: '/blog'
      },
      {
        id: uuidv4(),
        name: 'Single Post',
        path: '/single_post/post_1'
      },
      {
        id: uuidv4(),
        name: 'Newsletter',
        path: '/newsletter'
      }
    ]
  };

  const mobileCollapse = (id) => {
    if (isCollapse === id) {
      return setCollapse(null);
    }
    setCollapse(id);
  };

  return (
    <>
      {/* main desktop menu sart*/}
      <header className='js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors'>
      <div style={{backgroundColor: 'purple'}}>
          {/*<p className='text-white flex items-center justify-between py-3.5 text-base dark:text-white lg:px-10 w-full'>*/}
          {/*  During the sale all Moody Mights NFTs are locked in your*/}
          {/*  wallets. The trading will open right after the public*/}
          {/*  sale. GET READY! 🚀*/}
          {/*</p>*/}
        </div>
        <div className='flex items-center px-3 py-3 xl:px-24 '>
          <Link className='shrink-0' href='/' >
            <a>
              <div className='dark:hidden'>
                <Image
                  src={Logo}
                  height={28}
                  width={130}
                  alt='Traki | NFT Marketplace'
                  className='max-h-7 h-auto '
                />
              </div>
              <div className='hidden dark:block'>
                <Image
                  src={WhiteLogo}
                  height={28}
                  width={130}
                  alt='Traki | NFT Marketplace'
                />
              </div>
            </a>
          </Link>
          <Link href='https://zksync.io/' >
            <a>
              <div className='dark:hidden'>
                <Image
                  height={28}
                  width={130}
                  src='/artwork/zkSyncLogo3.png'
                  className='max-h-7 dark:hidden'
                  alt='Traki | NFT Marketplace'
                />
              </div>
              <div className='hidden dark:block'>
                <Image
                  height={28}
                  width={130}
                  src='/artwork/zkSyncLogo2.png'
                  className='hidden max-h-7 dark:block mb-6'
                  alt='Traki | NFT Marketplace'
                />
              </div>
            </a>
          </Link>
          {/* End  logo */}

          <div className='js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent'>
            <nav className='navbar w-full'>
              <ul className='flex flex-col lg:flex-row'>
                {/* home */}
                <li className='js-nav-dropdown group relative'>
                  <button className='dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full'>
                    <Link href='/' >
                      <span
                        className={
                          isParentPageActive(home.pages, route.asPath)
                            ? 'text-accent  dark:text-accent'
                            : ''
                        }
                      >
                        Home
                      </span>
                    </Link>
                    <i className='lg:hidden'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        width={24}
                        height={24}
                        className='h-4 w-4 dark:fill-white'
                      >
                        <path fill='none' d='M0 0h24v24H0z' />
                        <path d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' />
                      </svg>
                    </i>
                  </button>
                </li>

                <li
                  style={{ display: address ? '' : 'none' }}
                  className='js-nav-dropdown nav-item dropdown group relative'
                >
                  <button className='dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full'>
                    <Link href='/user' >
                      <span
                        className={
                          isParentPageActive(mint.pages, route.asPath)
                            ? 'text-accent dark:text-accent'
                            : ''
                        }
                      >
                        My assets
                      </span>
                    </Link>
                    <i className='lg:hidden'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        width={24}
                        height={24}
                        className='h-4 w-4 dark:fill-white'
                      >
                        <path fill='none' d='M0 0h24v24H0z' />
                        <path d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' />
                      </svg>
                    </i>
                  </button>
                </li>
                <li className='js-nav-dropdown nav-item dropdown group relative'>
                  <div className='flex lg:px-5 w-full'>
                    <ConnectWallet
                      theme={theme === 'dark' ? 'light' : 'dark'}
                      className="dark:bg-jacarta-100 bg-jacarta-700"
                      networkSelector={{ chains: [getProperChain() ] }}
                      switchToActiveChain={true} 
                    />
                  </div>
                </li>
              </ul>
            </nav>
            {/* End menu for desktop */}

            <div className='ml-8 hidden items-center lg:flex xl:ml-12'>
              <div className='group-dropdown relative'>
                <button
                  className='dropdown-toggle border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]'
                  onClick={toggleShoppingCart}
                >
                  <MdShoppingCart />
                  {selectedItems.length === 0 ? null : <span className='absolute top-0.5 right-2 text-xxs dark:bg-red p-0.5 rounded-full'>{selectedItems.length}</span>}
                </button>
                <div className={dropdownClasses}>
                  {/* List Start ===== */}
                    <List sx={{maxWidth: '400px'}} className='dark:bg-jacarta-800'>
                      {selectedItems.length > 0 ? selectedItems.map((element, idx) => {
                        const itemData = isActiveListing(element) ? element.asset : element.metadata;

                        return (
                          <React.Fragment key={itemData.id + ' ' + idx + 'key'}>
                            <ListItem key={itemData.id + ' ' + idx} alignItems="flex-start">
                              <ListItemAvatar>
                                <Avatar
                                  alt={itemData.name}
                                  src={itemData.image}
                                  variant="square"
                                  sx={{width: '50px', height: '50px', marginRight: '15px', borderRadius: '3px'}}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <React.Fragment>
                                    <Typography
                                      variant="h6"
                                    >
                                      {itemData.name}
                                    </Typography>
                                  </React.Fragment>
                                }
                                secondary={
                                  <React.Fragment>
                                    <Typography
                                      variant='span'
                                      className='dark:text-jacarta-100 light:text-jacarta-700 text-ellipsis overflow-hidden'
                                    >
                                      {itemData.description}
                                    </Typography>
                                  </React.Fragment>
                                }
                              />
                            </ListItem>
                            {idx + 1 < selectedItems.length ? <Divider className='dark:bg-jacarta-300 light:bg-jacarta-700' variant="inset" component="li"/> : null}
                            {idx + 1 >=  selectedItems.length ?
                              <div className='px-4 py-15'>
                                <Typography
                                  variant="h6"
                                >
                                  Total Price: {totalAmount} ETH
                                </Typography>
                              </div>
                              : null}
                          </React.Fragment>
                        )
                      }) : <Typography
                            variant="body1"
                            paddingY="20px"
                            paddingX='10px'
                          >
                            No items in the cart.
                          </Typography>}
                    </List>
                  {selectedItems.length > 0 ? <CheckoutDrawer /> : null}
                  {/* List End ===== */}
                </div>
              </div>
              <div className='js-nav-dropdown group-dropdown relative'>
                <button className='dropdown-toggle border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    width={24}
                    height={24}
                    className='fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white'
                  >
                    <path fill='none' d='M0 0h24v24H0z' />
                    <path d='M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z' />
                  </svg>
                </button>
                <div className='dropdown-menu dark:bg-jacarta-800 group-dropdown-hover:opacity-100 group-dropdown-hover:visible !-right-4 !top-[85%] !left-auto z-10 min-w-[14rem] whitespace-nowrap rounded-xl bg-white transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full lg:absolute lg:grid lg:!translate-y-4 lg:py-4 lg:px-2 lg:shadow-2xl hidden lg:invisible lg:opacity-0'>
                  <ConnectWallet
                    theme={theme === 'dark' ? 'light' : 'dark'}
                    className="dark:bg-jacarta-100 bg-jacarta-700"
                    networkSelector={{ chains: [getProperChain() ] }}
                    switchToActiveChain={true} 
                  />
                  <Link style={{ display: address ? '' : 'none' }} href='/user' >
                    <a
                      style={{ display: address ? '' : 'none' }}
                      className='dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        width={24}
                        height={24}
                        className='fill-jacarta-700 h-4 w-4 transition-colors dark:fill-white'
                      >
                        <path fill='none' d='M0 0h24v24H0z' />
                        <path d='M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z' />
                      </svg>
                      <span className='font-display text-jacarta-700 mt-1 text-sm dark:text-white'>
                        My Profile
                      </span>
                    </a>
                  </Link>

                  <button onClick={disconnect}>
                    <a className='dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        width={24}
                        height={24}
                        className='fill-jacarta-700 h-4 w-4 transition-colors dark:fill-white'
                      >
                        <path fill='none' d='M0 0h24v24H0z' />
                        <path d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM7 11V8l-5 4 5 4v-3h8v-2H7z' />
                      </svg>
                      <span className='font-display text-jacarta-700 mt-1 text-sm dark:text-white'>
                        Sign out
                      </span>
                    </a>
                  </button>
                </div>
              </div>
              <DarkMode />
            </div>
            {/* End header right content (metamask and other) for desktop */}
          </div>
          {/* header menu conent end for desktop */}

          <div className='ml-auto flex lg:hidden'>
            <Link href='/user' >
              <a
                style={{ display: address ? '' : 'none' }}
                className='border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]'
                aria-label='profile'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  width={24}
                  height={24}
                  className='fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white'
                >
                  <path fill='none' d='M0 0h24v24H0z' />
                  <path d='M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z' />
                </svg>
              </a>
            </Link>
            <DarkMode />
            <button
              className='js-mobile-toggle border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]'
              aria-label='open mobile menu'
              onClick={() => setToggle(true)}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                width={24}
                height={24}
                className='fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white'
              >
                <path fill='none' d='M0 0h24v24H0z' />
                <path d='M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z' />
              </svg>
            </button>
          </div>
          {/* End header right content  for mobile */}
        </div>

        {/* End flex item */}
      </header>
      {/* main desktop menu end */}

      {/* start mobile menu and it's other materials  */}
      <div
        className={`lg:hidden js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-20 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent ${
          toggle ? 'nav-menu--is-open' : 'hidden'
        }`}
      >
        <div className='t-0 dark:bg-jacarta-800 fixed left-0 z-10 flex w-full items-center justify-between bg-white p-6 lg:hidden'>
          <div className='dark:hidden'>
            <Image
              src={Logo}
              height={28}
              width={130}
              alt='Traki | NFT Marketplace'
              className='max-h-7 h-auto '
            />
          </div>

          <div className='hidden dark:block'>
            <Image
              src={WhiteLogo}
              height={28}
              width={130}
              alt='Traki | NFT Marketplace'
            />
          </div>

          <Link href='https://zksync.io/' >
            <Image
              height={28}
              width={130}
              src='/artwork/zkSyncLogo3.png'
              className='max-h-7 dark:hidden'
              alt='Traki | NFT Marketplace'
            />
          </Link>

          <Link href='https://zksync.io/' >
            <Image
              height={28}
              width={130}
              src='/artwork/zkSyncLogo2.png'
              className='hidden max-h-7 dark:block mb-6'
              alt='Traki | NFT Marketplace'
            />
          </Link>

          {/* <button
            className='js-mobile-close border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]'
            onClick={() => setToggle(false)}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              width={24}
              height={24}
              className='fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white'
            >
              <path fill='none' d='M0 0h24v24H0z' />
              <path d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z' />
            </svg>
          </button> */}
        </div>
        {/* mobile menu top header content */}
        {/* End search form mobile menu  */}

        <nav className='navbar w-full'>
          <ul className='flex flex-col lg:flex-row'>
            <li className='js-nav-dropdown group relative'>
              <button
                onClick={() => mobileCollapse(home.id)}
                className='dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full'
              >
                <span
                  className={
                    isParentPageActive(home.pages, route.asPath)
                      ? 'text-accent dark:text-accent'
                      : ''
                  }
                >
                  {'home'}
                </span>
                <i className='lg:hidden'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    width={24}
                    height={24}
                    className='h-4 w-4 dark:fill-white'
                  >
                    <path fill='none' d='M0 0h24v24H0z' />
                    <path d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' />
                  </svg>
                </i>
              </button>
            </li>
            <li className='js-nav-dropdown group relative'>
              <button
                onClick={() => mobileCollapse(page.id)}
                className='dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full'
              >
                <span
                  className={
                    isParentPageActive(list.pages, route.asPath)
                      ? 'text-accent dark:text-accent'
                      : ''
                  }
                >
                  {list.name}
                </span>
                <i className='lg:hidden'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    width={24}
                    height={24}
                    className='h-4 w-4 dark:fill-white'
                  >
                    <path fill='none' d='M0 0h24v24H0z' />
                    <path d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' />
                  </svg>
                </i>
              </button>
              <ul
                className={`dropdown-menu left-0 top-[85%] z-10 grid-flow-row grid-cols-[repeat(2,_1fr)] gap-x-4 whitespace-nowrap rounded-xl bg-white transition-all will-change-transform group-hover:visible group-hover:opacity-100 dark:bg-jacarta-800 lg:invisible lg:absolute lg:!grid lg:translate-y-4 lg:py-8 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 relative ${
                  isCollapse === list.id ? 'block' : 'hidden'
                }`}
              >
                {list?.pages?.map((page) => (
                  <li key={page.id} onClick={() => setToggle(false)}>
                    <Link href={page.path} >
                      <a className='dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-xl px-5 py-2 transition-colors justify-between'>
                        <span
                          className={
                            isChildrenPageActive(page.path, route.asPath)
                              ? 'text-accent dark:text-accent'
                              : ''
                          }
                        >
                          {page.name}
                        </span>
                        {page.condition ? (
                          <span className='rounded bg-green py-1 px-2 text-tiny font-bold uppercase leading-none text-white ml-4'>
                            new
                          </span>
                        ) : undefined}
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            <li className='group' onClick={() => setToggle(false)}>
              <Link href='/' >
                <a>
                  <button className='text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5'>
                    Home
                  </button>
                </a>
              </Link>
            </li>

            <li className='group' onClick={() => setToggle(false)}>
              <Link href='/user' >
                <a style={{ display: address ? '' : 'none' }}>
                  <button className='text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5'>
                    My Assets
                  </button>
                </a>
              </Link>
            </li>
          </ul>
        </nav>
        {/* End navbar mobile menu  */}

        <div className='mt-10 w-full lg:hidden'>
          <ConnectWallet
            theme={theme === 'dark' ? 'light' : 'dark'}
            className="dark:bg-jacarta-100 bg-jacarta-700"
            networkSelector={{ chains: [getProperChain() ] }}
            switchToActiveChain={true} 
          />
        </div>
        {/* mt-10 w-full lg:hidden */}
      </div>
      {/* End mobile menu and it's other materials */}
    </>
  );
}
