const footerMenuList = [
  {
    id: 1,
    title: 'Company',
    diffClass: '',
    list: [
      {
        id: 1,
        href: 'https://cryptomaze.app/',
        text: 'About'
      },
      {
        id: 2,
        href: 'https://cryptomaze.app/#footer',
        text: 'Contact Us'
      }
    ]
  },
  {
    id: 2,
    title: 'My Account',
    diffClass: '',
    list: [
      {
        id: 1,
        href: '/user',
        text: 'My Listings'
      },
      {
        id: 2,
        href: '/list',
        text: 'List NFT'
      },
      {
        id: 3,
        href: '/user',
        text: 'My Profile'
      }
    ]
  }
];

const socialIcons = [
  {
    id: 1,
    href: 'http://cryptomaze.app/',
    text: 'website'
  },
  {
    id: 2,
    href: 'https://twitter.com/CryptoMazeApp',
    text: 'twitter'
  },
  {
    id: 3,
    href: 'https://discord.com/invite/cryptomazeapp',
    text: 'discord'
  }
];

export { footerMenuList, socialIcons };
