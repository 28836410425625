import { Config } from "./config"

const isProduction = () => {
  return Config.ENVIRONMENTS.PROD === process.env.NEXT_PUBLIC_ENVIRONMENT
}

const getProperChain = () => {
  return isProduction() ? Config.CHAINS.MAINNET : Config.CHAINS.TESTNET;
}

const capitalizeString = (string) => {
  return string[0].toUpperCase() + string.slice(1, string.length)
}

export { isProduction, getProperChain, capitalizeString }